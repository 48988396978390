<template>
	<v-sheet>
		<h4>{{project.internal_name}}</h4>
		<v-list>
			<template v-for="participant in participants">
				<v-list-item :key="participant.id" color="secondary">
					<v-list-item-content>
						<v-list-item-title>{{ participant.name }}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn icon @click="restore(participant.id)"
							><v-icon>mdi-restore</v-icon></v-btn
						>
					</v-list-item-action>
				</v-list-item>
			</template>
		</v-list>
		<secondary-navigation show-back> 
			<template v-slot:append>
				<edit-project-notes v-model="id"></edit-project-notes>
			</template>
		</secondary-navigation>
	</v-sheet>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import { arrayRemove } from "vuex-easy-firestore";
import EditProjectNotes from '@c/edits/EditProjectNotes';
export default {
	name: "ExcludedParticipants",
	props: {
		id: { type: String },
	},
	components: {
		SecondaryNavigation,
		EditProjectNotes
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		excluded_participants() {
			return this.project ? this.project.exclude_participants : [];
		},
		participants() {
			const self = this;
			return self.excluded_participants.map((p) => {
				return self.$store.state.users.data[p];
			});
		},
	},
	methods: {
		restore(id) {
			this.$store.dispatch("projects/patch", {
				id: this.id,
				exclude_participants: arrayRemove(id),
			});
		},
	},
	watch: {
		project: {
			deep: true,
			handler() {
				this.$store.dispatch(
					"users/fetchByIds",
					this.project.exclude_participants
				);
			},
		},
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
	},
};
</script>
