<template>
	<v-navigation-drawer
		v-model="drawer"
		app
		:mini-variant="mini"
		permanent
		right
		style="z-index: 100"
	>
		<template v-if="showBack">
			<nav-item v-if="!mini"
				@click="goBack"
				text="Back"
				icon="mdi-arrow-left-circle"
			></nav-item>
			<v-btn v-else icon @click="goBack" class="ml-2 mt-1">
				<v-icon>mdi-arrow-left-circle</v-icon>
			</v-btn>
			<v-divider></v-divider>
		</template>
		<v-list dense>
			<slot name="main"></slot>
		</v-list>
		<template v-slot:append>
			<v-list dense>
				<slot name="append"></slot>
				<nav-item
					@click="mini = !mini"
					:text="$t('buttons.collapse')"
					:icon="mini ? 'mdi-chevron-left' : 'mdi-chevron-right'"
				></nav-item>
			</v-list>
		</template>

	</v-navigation-drawer>
</template>
<script>
import NavItem from "@c/navigation/Item";
export default {
	name: "SecondaryNavigation",
	props: {
		showBack: {type: Boolean, default: false }
	},
	computed: {

	},
	data() {
		return {
			drawer: true,
			mini: false,
		};
	},
	components: {
		NavItem
	},
	methods: {
		goBack(){
			this.$router.go(-1)
		}
	}
};
</script>
