<template>
	<v-bottom-sheet v-model="bottom">
		<template v-slot:activator="{ on }">
			<div class="pa-3">
				<v-btn small block v-on="on" color="primary">Internal Notes</v-btn>
			</div>
		</template>
		<v-sheet>
			<mw-editor v-if="project"
				v-model="project.internal_notes"
			></mw-editor>
		</v-sheet>
	</v-bottom-sheet>
</template>

<script>
import MwEditor from "@c/ui/MwEditor";
export default {
	name: "EditProjectNotes",
	props: {
		value: { type: String },
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.value];
		},
	},
	data: () => {
        return {
            bottom: null,
		};
	},
    watch: {
        bottom(v) {
            if( !v ){
                this.save()
            }
        }
    },
	methods: {
		save() {
			this.$store.dispatch("projects/patch", {
				id: this.value,
				internal_notes: this.project.internal_notes,
			});
		},
	},
	components: {
		MwEditor,
	},
};
</script>
